$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$(document).ready(function(){

	var url = (window.location.href).replace('http://18.230.174.40', '')

    // Insere o contorno na página ativa no menu
    switch (url) {
	case '/':
		$('#HLnavInicio').addClass('hl-active');
		break;
	case '/quemsomos':
		$('#HLnavAHappyLife').addClass('hl-active');
		break;
	case '/equipe':
	 	$('#HLnavAHappyLife').addClass('hl-active');
		break;
	case '/parceiros':
		$('#HLnavParceiros').addClass('hl-active');
		break;
	case '/servicos':
		$('#HLnavServicos').addClass('hl-active');
		break;
	case '/contato':
		$('#HLnavContato').addClass('hl-active');
	}

	$('a.nav-link [href="' + url + '"]').parent().addClass('hl-active');

	//Oculta o menu quando rolar para baixo e exibe quando rolar para cima  
	// var prevScrollpos = window.pageYOffset;
	// window.onscroll = function() {
	//   var currentScrollPos = window.pageYOffset;
	//   if (prevScrollpos > currentScrollPos) {
	//     document.getElementById("HLNavbar").style.top = "0";
	//   } else {
	//     document.getElementById("HLNavbar").style.top = "-100px";
	//   }
	//   prevScrollpos = currentScrollPos;
	// };

	//Realiza a assinatura no mailchimp e obtem retorno de forma assíncrona
	jQuery('#HL-btn-assinar').click(function(){

				jQuery.ajax({
				url: "/newsletter",
				method: 'post',
				data: {
				nlNome: jQuery('#nlNome').val(),
				nlEmail: jQuery('#nlEmail').val(),
				},
                success: function(retorno_nl) 
                {
                    $('#HL-nl-assinar').html(retorno_nl).fadeIn();    
                },
                beforeSend: function() { 
                 	$('.loading').fadeIn('fast'); 
            	},
             	complete: function(){ 
                 	$('.loading').fadeOut('fast'); 
             	},
                error: function(retorno_nl)
                {
                    $('#HL-nl-assinar').html(retorno_nl).fadeIn(); 
                }
           });

        });

	//Envia o email para info de forma assíncrona
	jQuery("#HL-btn-enviarMail").click(function() {

		 //Validando
        if(jQuery('#mailNome').val().length <= 3){
            window.alert('Informe seu nome');
            return false;
        }
        if(jQuery('#mailEmail').val().length <= 5){
            window.alert('Informe seu email');
            return false;
        }
        if(jQuery('#mailMensagem').val().length <= 5){
            window.alert('Escreva uma mensagem');
            return false;
        }
        //atribui um valor à variavel para diferenciar posteriormente
        var newsletter = 'uncheck'
		if(document.HLFrmMail.mailNl.checked == true){
			newsletter = 'check';
		}

		//Envia o email
		$.ajax
            ({ 
                url: '/sendmail', 
                async: true,
                method: 'post',
				data: {
					tipo: jQuery('#mailTipoCliente').val(),
					assunto: jQuery('#mailAssunto').val(),
					nome: jQuery('#mailNome').val(),
					remetente: jQuery('#mailEmail').val(),
					ddd: jQuery('#mailDDD').val(),
					tel: jQuery('#mailTel').val(),
					mensagem: jQuery('#mailMensagem').val(),
					newsletter: newsletter
				}, 
                success: function(retorno) 
                {
		        	//Verifica checkbox
			        if(newsletter == 'check')
					{
						//Realiza a assinatura na newsletter
						$.ajax
			            ({
			                type: 'POST', 
			                url: '/newsletter', 
			                async: true,
							data: {
								nlTipo: jQuery('#mailTipoCliente').val(),
								nlNome: jQuery('#mailNome').val(),
								nlEmail: jQuery('#mailEmail').val(),
							},
							success: function(retorno_nl) {
								$('#HLFrmMail').append('<h6 class="hl-text-1">' + retorno_nl + '</h6>').fadeIn();
							},
							error: function(retorno_nl) {
								$('#HLFrmMail').append('<h6 class="hl-text-1">' + retorno_nl + '</h6>').fadeIn();
							}
				        })     
                	}
                	$('#HLFrmMail').html(retorno).fadeIn();
                },	
                beforeSend: function() { 
                 $('.loading2').fadeIn('fast'); 
            	},
             	complete: function(){ 
                 $('.loading2').fadeOut('fast'); 
             	},        
                error: function(retorno)
                {
                   $("#HLFrmMail").html(retorno).fadeIn(); 
                }
           	});		
	});

	//POLITICA DE PRIVACIDADE - Envia o email para info de forma assíncrona
	jQuery("#HL-btn-enviarMailLgpd").click(function() {
		//Validando
	   if(jQuery('#lgpdNome').val().length <= 3){
		   window.alert('Informe seu nome');
		   return false;
	   }
	   if(jQuery('#lgpdCpf').val().length <= 3){
			window.alert('Informe seu CPF');
			return false;
		}
		if(jQuery('#lgpdRg').val().length <= 3){
			window.alert('Informe seu RG');
			return false;
		}
		if(jQuery('#lgpdDtNasc').val().length <= 3){
			window.alert('Informe sua data de nascimento');
			return false;
		}
		if(jQuery('#lgpdEmail').val().length <= 5){
			window.alert('Informe seu email');
			return false;
		}
		if(jQuery('#lgpdTel').val().length <= 5){
			window.alert('Informe seu telefone');
			return false;
		}
		if(jQuery('#lgpdTipoSolicitacao').val().length <= 5){
			window.alert('Informe o Tipo de solicitacao');
			return false;
		}

	   //Envia o email
	   $.ajax
		({ 
			url: '/mailLgpd', 
			async: true,
			method: 'post',
			data: {
				assunto: 'LGPD',
				nome: jQuery('#lgpdNome').val(),
				cpf: jQuery('#lgpdCpf').val(),
				rg: jQuery('#lgpdRg').val(),
				dtNasc: jQuery('#lgpdDtNasc').val(),
				remetente: jQuery('#lgpdEmail').val(),
				tel: jQuery('#lgpdTel').val(),
				tipo: jQuery('#lgpdTipoSolicitacao').val(),
				mensagem: jQuery('#lgpdMensagem').val(),
			}, 
			success: function(retorno) 
			{
				$('#CL-FORM').append('<h6 class="hl-text-1">' + retorno + '</h6>').fadeIn();
			},	
			beforeSend: function() { 
				$('.loading2').fadeIn('fast'); 
			},
				complete: function(){ 
				$('.loading2').fadeOut('fast'); 
			},        
			error: function(retorno)
			{
				console.log(retorno);
				$('#CL-FORM').append('<h6 class="hl-text-1">' + retorno + '</h6>').fadeIn();
			}
		});		
   });
}); 
