$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$(document).ready(function(){

	$("#HL-btn-formDepoimento").click(function() {

        $('html, body').animate({ scrollTop:  $('#HL-btn-formDepoimento').offset().top - 120}, 'slow')
    
	});

	//Envia o email para info de forma assíncrona
	jQuery("#HL-btn-enviarDepoimento").click(function() {

		 //Validando
        if(jQuery('#depoimentoNome').val().length <= 3){
            window.alert('Informe seu nome');
            return false;
        }
        if(jQuery('#depoimentoEmail').val().length <= 5){
            window.alert('Informe seu email');
            return false;
        }
        if(jQuery('#depoimentoMensagem').val().length <= 5){
            window.alert('Escreva uma mensagem');
            return false;
        }

		//Envia o email
		$.ajax
        ({ 
            url: '/enviardepoimento', 
            async: true,
            method: 'post',
			data: {
				assunto: 'Depoimento',
				nome: jQuery('#depoimentoNome').val(),
				remetente: jQuery('#depoimentoEmail').val(),
				mensagem: jQuery('#depoimentoMensagem').val(),
			}, 
            success: function(retorno) {
            	$('#HLFrmDepoimento').html(retorno).fadeIn();
            },	
            beforeSend: function() { 
            	$('.loading3').fadeIn('fast'); 
        	},
         	complete: function(){ 
            	$('.loading3').fadeOut('fast'); 
         	},        
            error: function(retorno)
            {
            	$("#HLFrmDepoimento").html(retorno).fadeIn(); 
            }
       	});	
	});


    $("#HL-btn-depoimentos-1").click(function() {

        //oculta empresas e exibe beneficiarios
        $("#carouselDepoimentoEmpresa").removeClass("collapse show");
        $("#carouselDepoimentoEmpresa").addClass("collapse in");
        $("#carouselDepoimentoBeneficiario").removeClass("collapse in");
        $("#carouselDepoimentoBeneficiario").addClass("collapse show");

        //botao 1 ativo e botao 2 não ativo
        $("#HL-btn-depoimentos-1").addClass("btn-pg-depoimentos-active");
        $("#HL-btn-depoimentos-1").removeClass("btn-pg-depoimentos");
        $("#HL-btn-depoimentos-2").removeClass("btn-pg-depoimentos-active");
        $("#HL-btn-depoimentos-2").addClass("btn-pg-depoimentos");

    });

    $("#HL-btn-depoimentos-2").click(function() {

        //oculta beneficiarios e exibe empresas
        $("#carouselDepoimentoBeneficiario").removeClass("collapse show");
        $("#carouselDepoimentoBeneficiario").addClass("collapse in");
        $("#carouselDepoimentoEmpresa").removeClass("collapse in");
        $("#carouselDepoimentoEmpresa").addClass("collapse show");

        //botao 2 ativo e botao 1 não ativo
        $("#HL-btn-depoimentos-2").addClass("btn-pg-depoimentos-active");
        $("#HL-btn-depoimentos-2").removeClass("btn-pg-depoimentos");
        $("#HL-btn-depoimentos-1").removeClass("btn-pg-depoimentos-active");
        $("#HL-btn-depoimentos-1").addClass("btn-pg-depoimentos");

    });

}); 
