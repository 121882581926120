$(document).ready(function(){

  	//Exibe as informações dos colaboradores individualmente

	$(".hl-equipe-foto").hover(
		function() {

			var id = $(this).attr('id');

			var nome_completo = $('#' + id + '-nome_completo').html();
			var formacao = $('#' + id + '-formacao').html();
			var hobbies = $('#' + id + '-hobbies').html();
			var frase = $('#' + id + '-frase').html();

			if (nome_completo) {
				var html = "<h5 class='hl-text-1'>" + nome_completo + "</h5><p>"
			}
			if (formacao) {
				html += "<p>Formação: <strong>" + formacao + "</strong></p>"
			}
			if (hobbies) {
				html += "<p>Hobbies: <strong>" + hobbies + "</strong></p>"
			}
			if (frase) {
				html += "<p>Uma frase que inspira: <strong>" + frase + "</strong></p>"
			}

	    	$('#HL-equipe-info').html(html);
	  		$("#HL-equipe-info").animate({opacity: '1'},500); 

	  }, function() {

	  		$("#HL-equipe-info").animate({opacity: '0'});

	  });
	
});