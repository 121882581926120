$(document).ready(function(){

	//preenche os valores do dropdown
	$( ".hl-filtro-radio" ).click(function(){

		   var regiao = $("input:checked").val();

		   	if (regiao == 'sp') {
			  var cobertura = ['ABC Paulista', 'Atibaia', 'Bragança Paulista', 'SP Capital', 'Grande SP', 'Interior', 'Jundiai', 'Litoral']
			} else if (regiao == 'rj') {
			  var cobertura =['RJ Capital', 'Região Metropolitana', 'Região Serrana']
			} else {
			  var cobertura = ['AL', 'AM', 'BA', 'CE', 'GO', 'MA', 'MS', 'MT', 'PA', 'PB', 'PI', 'PR', 'RN', 'SC', 'SE']
			}

			var opcaoHtml = ""

			$.each(cobertura, function(cobertura, i) {

				opcaoHtml += '<option value="' + i + '">' + i + '</option>'

			})

			$('#HL-slc-selos').html(opcaoHtml);
			$("#HL-slc-selos").trigger('change');

	});

 	//insere o html ao selecionar a região de operadora no filtro
	$( "#HL-slc-selos" ).change(function() {

		jQuery.ajax({
			url: "/filtramarcas",
			method: 'post',
			data: {
				cobertura: jQuery('#HL-slc-selos').val(),
			},
            success: function(marcas) {

            	var html = "";
            	if  (marcas.length) {

	            	$.each(marcas, function (index, item) {

					 html += '<div id="HL-selo" class="col-xl-2 col-md-4 col-sm-6 marcar-2 hl-selo-1">'
					 html += '<a href="/operadoras/' + item.slug + '"><img src="/images/selos/' + item.logo + '"></a>'
					 html += '</div>'

					})
					$('#HL-selos-linha-2').html(html);
            	} else {

            		$('#HL-selos-linha-2').html('<h5 class="hl-text-1">Ainda não possuimos parceiros em sua região, entre em contato para consultarmos a melhor opção.</h5>').fadeIn();
            	}

            	$('#HL-selos-linha-1').hide();
				$('#HL-selos-linha-2').hide().show('slow');
            }
		});

		$('#HL-btn-selos').hide();
	});

	$('#HL-btn-mostrarTodos').click(function() {

		$('#HL-selos-linha-2').hide();
		$('#HL-selos-linha-1').hide().show('slow');
		$('#HL-btn-selos').hide().show('slow');

		if($('#HL-btn-selos i').hasClass('fa-sort-down')){
			$("#HL-btn-selos").trigger('click');
		}


	});

 });
