$(document).ready(function(){

	//Rola a página para a o formulário de orçamento ao clicar no botão
	$("#HL-btn-orcamento").click(function() {

    $('html, body').animate({ scrollTop:  $('#HL-orcamento').offset().top - 50 }, 'slow')
    
	});

	//Exibe ou oculta os demais selos e altera o icone da seta
	$(".hl-selos-ocultos").on("hide.bs.collapse", function(){
  	$("#HL-btn-selos").html('<i class="fas fa-sort-down fa-2x">');
	});

	$(".hl-selos-ocultos").on("show.bs.collapse", function(){
  	$("#HL-btn-selos").html('<i class="fas fa-sort-up fa-2x">');
	});

	//Exibe o texto de Saiba mais dos serviços
	$(".hl-saibamais").click(function() {

		var id = $(this).attr('id')
		var card_id = id.substring(13)

		var descricao = $('#' + id + '-texto').html()

		$(".hl-box").removeClass('hl-box-active')
		$("#HL-box-" + card_id).addClass('hl-box-active')

	    $(".hl-box-texto").removeClass('hl-box-texto-active')
	    $("#HL-box-texto-" + card_id).addClass('hl-box-texto-active')

	    $("#HL-saibamais-descr").html(descricao)

	 		var div = $("#HL-saibamais-descr")
	 		div.height(0)
	    	div.animate({height: '100%'}, "slow")

	    $('html, body').animate({ scrollTop:  $('#HL-saibamais-descr').offset().top - 400 }, 'slow');
    
	});

}); 