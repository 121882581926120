$(document).ready(function(){

		$("#HL-btn-clientes-1").click(function() {

			//oculta lista e exibe logo
			$("#HL-clientes-lista").removeClass("collapse show");
			$("#HL-clientes-lista").addClass("collapse in");
			$("#HL-clientes-logos").removeClass("collapse in");
			$("#HL-clientes-logos").addClass("collapse show");

			//botao 1 ativo e botao 2 não ativo
			$("#HL-btn-clientes-1").addClass("btn-pg-clientes-active");
			$("#HL-btn-clientes-1").removeClass("btn-pg-clientes");
			$("#HL-btn-clientes-2").removeClass("btn-pg-clientes-active");
			$("#HL-btn-clientes-2").addClass("btn-pg-clientes");

		});

		$("#HL-btn-clientes-2").click(function() {

			//oculta logo e exibe lista
			$("#HL-clientes-logos").removeClass("collapse show");
			$("#HL-clientes-logos").addClass("collapse in");
			$("#HL-clientes-lista").removeClass("collapse in");
			$("#HL-clientes-lista").addClass("collapse show");

			//botao 2 ativo e botao 1 não ativo
			$("#HL-btn-clientes-2").addClass("btn-pg-clientes-active");
			$("#HL-btn-clientes-2").removeClass("btn-pg-clientes");
			$("#HL-btn-clientes-1").removeClass("btn-pg-clientes-active");
			$("#HL-btn-clientes-1").addClass("btn-pg-clientes");

		});

});