require('./bootstrap');

// Demais scripts podem ser incluídos como abaixo:
require('./script')
require('./clientes')
require('./equipe')
require('./filter')
require('./index')
require('./depoimento')

